var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("h3", [_vm._v("Scan (active i2c)")]),
      _c("v-btn", { on: { click: _vm.scani2c } }, [_vm._v(" scani2c ")]),
      _c("h4", [_vm._v(" " + _vm._s(_vm.infoData) + " ")]),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.mode,
              expression: "mode",
            },
          ],
          on: {
            change: function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.mode = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            },
          },
        },
        [_c("option", { attrs: { value: "csv" } }, [_vm._v(" CSV Editing ")])]
      ),
      _vm.mode === "basic"
        ? _c(
            "div",
            [
              _c("vue-grid-sheet", {
                ref: "grid",
                attrs: {
                  data: _vm.demoBasic.data,
                  format: _vm.demoBasic.format,
                  state: _vm.demoBasic.state,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.mode === "csv"
        ? _c("div", { staticClass: "pane" }, [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.dataSrc,
                  expression: "dataSrc",
                },
              ],
              domProps: { value: _vm.dataSrc },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.dataSrc = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "right" },
              [
                _c("vue-spreadsheet-lite", {
                  attrs: { data: _vm.gridData },
                  on: { update: _vm.onUpdate },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }